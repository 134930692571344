import React, { useCallback, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { styled } from "goober";
import TextInput from "clutch/src/TextInput/TextInput.jsx";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import ApexDefinition from "@/game-apex/definition.mjs";
import ShareIcon from "@/inline-assets/share.svg";
import globals from "@/util/global-whitelist.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function setApexPlatformId(id) {
  const searchParams = new URLSearchParams(router.route.searchParams);
  searchParams.set("apexPlatformId", id);
  updateRoute(router.route.currentPath, searchParams, router.route.state);
}

function setVariant(variant) {
  const searchParams = new URLSearchParams(router.route.searchParams);
  searchParams.set("variant", variant);
  updateRoute(router.route.currentPath, searchParams, router.route.state);
}

function getVariant() {
  return router.route.searchParams.get("variant") || "variant1";
}

const Copypaste = styled("div")`
  max-width: 30rem;
  background: var(--shade9);
  padding: var(--sp-2) var(--sp-4);
  border-radius: var(--br);
  display: flex;
  justify-content: space-between;

  code {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 3em);
  }
`;

const IconContainer = styled("span")`
  cursor: pointer;
`;

const FakeLink = styled("span")`
  cursor: pointer;
  color: var(--blue);
`;

const TextContainer = styled("div")`
  display: flex;
  gap: var(--sp-4);
  align-items: center;

  > * {
    flex: 2;
  }
  > label {
    flex: 3;
  }
`;

// Add some offset to be safe.
const DIMENSIONS_PADDING = 20;

export default [
  () => {
    const { searchParams } = useRoute();
    const apexPlatformId = searchParams.get("apexPlatformId") ?? "";
    const {
      settings,
      apex: { profiles },
    } = useSnapshot(readState);
    const { t } = useTranslation();
    const lastId = apexPlatformId || settings.lastLoggedInIdByGame.apex;

    useEffect(() => {
      if (lastId) {
        setApexPlatformId(lastId ?? "");
      }
    }, [lastId]);

    const tryExample = useCallback(() => {
      const ids = ApexDefinition.meta.pros.map(({ id }) => id);
      setApexPlatformId(ids[Math.floor(Math.random() * ids.length)]);
    }, []);

    const name = profiles[apexPlatformId]?.username;

    return (
      <>
        <div className="type-body1-form--active">
          {t("apex:platformId", "Platform ID")}
        </div>
        <TextContainer>
          <TextInput
            placeholder={t("apex:platformId", "Platform ID")}
            value={apexPlatformId}
            onValueChange={setApexPlatformId}
          />
          <span className="type-caption shade4">{name}</span>
        </TextContainer>
        <p className="type-body2 shade1">
          <Trans i18nKey="apex:platformIdDescription">
            <span>
              Platform ID is an internal identifier, we auto-detect this when
              you run Apex Legends.{" "}
            </span>
            <FakeLink onClick={tryExample}>Try an example</FakeLink>
          </Trans>
        </p>
      </>
    );
  },
  () => {
    const { searchParams } = useRoute();
    const apexPlatformId = searchParams.get("apexPlatformId");
    const variant = searchParams.get("variant");
    const { t } = useTranslation();
    const {
      volatile: { streamerDimensions },
    } = useSnapshot(readState);

    const dimensions = streamerDimensions
      ? t("common:dimensions", "Dimensions: {{width}} x {{height}}", {
          width: Math.ceil(streamerDimensions.w + DIMENSIONS_PADDING),
          height: Math.ceil(streamerDimensions.h + DIMENSIONS_PADDING),
        })
      : null;

    const params = new URLSearchParams({
      id: apexPlatformId,
      variant,
    });
    const url = `${globals.location.origin}/apex/overlay/stream?${params}`;

    const copyLink = useCallback(() => {
      navigator.clipboard.writeText(url);
    }, [url]);

    if (!apexPlatformId) return null;

    return (
      <>
        <div className="type-body1-form--active">
          {t("common:overlayURL", "Overlay URL")}
        </div>
        <p className="type-body2 shade1">
          {t(
            "common:overlayURLDescription",
            "Copypaste this into your streaming app (in OBS Studio: Sources → Browser).",
          )}
        </p>
        <Copypaste className="type-body2 shade1">
          <code>{url}</code>
          <IconContainer
            onClick={copyLink}
            data-tooltip={t(
              "common:share.copied",
              "Web link copied to clipboard!",
            )}
            data-event="click"
          >
            <ShareIcon width="20" />
          </IconContainer>
        </Copypaste>
        <p className="type-body2 shade4">{dimensions}</p>
      </>
    );
  },
  {
    text: [
      "overlays:lol.skillOrder.options.displayOptions.title",
      "Display Options",
    ],
    items: [
      {
        select: {
          style: "radio",
          value: getVariant,
          onChange: setVariant,
          options: [
            {
              value: "variant1",
              text: [
                "overlays:variant",
                "Variant #{{number}}",
                {
                  number: 1,
                },
              ],
            },
            {
              value: "variant2",
              text: [
                "overlays:variant",
                "Variant #{{number}}",
                {
                  number: 2,
                },
              ],
            },
          ],
        },
      },
    ],
  },
];
